/* FOR BLOG DIRECTORY
/* BLOG.SCSS -> BLOG.CSS
------------------------------ */
.bottom-links.under-category-links {
	padding-top: 148px;
	background: #E8EDEB url(../img/common/wave_orange.png) top center repeat-x;
}

.blog.index,
.blog.category,
.blog.tags {
	.post-lists {
		padding-bottom: 4rem;
		.container {
			max-width: 1100px;
			padding-left: 0;
			padding-right: 0;
		}
		.post-item {
			padding-left: 1.25rem;
			padding-right: 1.25rem;
			margin-bottom: 2.5rem;
		}
		.post-wrapper {
			height: 100%;
			border: 2px solid #f58700;
			border-radius: .75rem;
			background-color: #fff;
			padding: 26px 25px 22px 25px;
			box-shadow: 2px 2px 0 1px #E6EBE9;
			.post-title {
				font-size: 1.25rem;
				margin-bottom: 1.5rem;
				a {
					color: #333;
				}
			}
			.post-thumb {
				img {
					// max-width: 100%;
					// width: 100%;
					width: 180px;
					height: 120px;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
			.post-datas {
				padding-left: .5rem;
				p {
					font-size: .8125rem;
					margin-bottom: 0;
				&.post-meta {
					color: #f58700;
					margin-bottom: .25rem;
				}
				}
			}
		}
	}
}

.blog.indexwp {
	.post-content {
		min-height: 480px;
	}
}

.blog.notfound {
	.post-single-t2 {
		min-height: 480px;
		padding-bottom: 6rem;
		.container {
			max-width: 800px;
			padding-left: 0;
			padding-right: 0;
		}
		.post-title {
			width: 595px;
			margin: 0 auto 4rem;
			min-height: 74px;
			background: url(../img/common/or_bracket_left.png) left top no-repeat;
			.bracket-wrapper {
				min-height: 74px;
				background: url(../img/common/or_bracket_right.png) right bottom no-repeat;
			}
			h3 {
				text-align: center;
				font-size: 1.375rem;
				padding-left: 1rem;
				padding-right: 1rem;
				padding-top: 1.5rem;
			}
		}
		.post-body {
			padding-bottom: 4rem;
			p {
				margin-bottom: 0;
			}
		}
	}
&.notfound {
	.post-single-t2 {
		.post-title {
			h3 {
				color: #f58700;
			}
		}
		.post-body {
			p {
				margin-bottom: 1rem;
				a {
					text-decoration: underline;
				}
			}
		}
	}
}
}

.works.index,
.works.category,
.works.tags {
	.post-lists {
		.post-wrapper {
			padding: 26px 25px 22px 25px;
		}
	}
}

.faq.index,
.faq.category,
.faq.tags {
	.post-lists {
		.post-item {
			max-width: 796px;
			margin: 0 auto 2.5rem;
		}
		.post-wrapper {
			.post-title {
				font-size: 1.25rem;
				margin-bottom: .5rem;
				padding-left: 2.5rem;
				line-height: 1.5rem;
				background: url(../img/faq/icon_q.png) 0 0 no-repeat;
			}
			.post-datas {
				margin-left: 2rem;
			}
		}
	}
}

.voice.index,
.voice.category,
.voice.tags {
	.post-lists {
		.post-wrapper {
			padding: 2.25rem 3rem 2rem 3rem;
		}
		.post-item {
			max-width: 900px;
			margin: 0 auto 2.5rem;
		}
	}
}

.topics.index,
.topics.category,
.topics.tags {
	.post-lists {
		.post-wrapper {
			padding: 2.25rem 3rem 2rem 3rem;
		}
		.post-item {
			max-width: 900px;
			margin: 0 auto 2.5rem;
		}
	}
}

/* CATEGORY LIST AT PAGE BOTTOM
/* FOR WORD PRESS TEMPLATES
/* (.unit.category-list.toco)
------------------------------ */
.blog {
	.category-list {
		.bg-wrapper {
			padding-bottom: 2rem;
		}
		.container {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
		}
		color: #fff;
		h3 {
			font-size: 1.3125rem;
			margin-bottom: 3rem;
			font-weight: normal;
		}
		ul {
			max-width: 1050px;
			margin: 0 auto;
			padding-left: 0;
			text-align: left;
			display: inline-flex;
			flex-wrap: wrap;
			li {
				min-width: 270px;
				list-style-position: inside;
				line-height: 42px;
				border-bottom: 2px solid #fff;
				margin-left: 40px;
				margin-right: 40px;
				a {
					color: #fff;
				}
			}
		}
	}
}

.blog.single {
	.post {
		padding-bottom: 4rem;
		.container {
			max-width: 1060px;
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	.post-content {
			padding-right: 40px;
		}
	.sidebar {
		padding-left: 40px;
		ul {
			padding-left: 0;
			margin-bottom: 2rem;
			li {
				list-style-position: inside;
				line-height: 42px;
				border-bottom: 1px solid #f58700;
				a {
					color: #333;
				}
			}
		}
	}
}


.blog.search {
	.largeclass-name {
		margin-bottom: 2rem;
	}
	.post-list {
		.container {
			max-width: 1060px;
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	.post-content {
		padding-bottom: 4rem;
		padding-right: 40px;
		.post-item {
			margin-bottom: 2.5rem;
		}
		.post-title {
			font-size: 1.25rem;
			margin-bottom: .25rem;
			a {
				color: #333;
			}
		}
		.post-datas {
			p {
				font-size: .8125rem;
				margin-bottom: 0;
			&.post-date {
				margin-bottom: 0;
				a {
					color: #333;
				}
			}
			&.post-meta {
				color: #f58700;
				margin-bottom: .25rem;
				a {
					color: #f58700;
				}
			}
			}
		}
		.pagelinks {
			padding-left: 0;
		}
	}
	.sidebar {
		padding-left: 40px;
		ul {
			padding-left: 0;
			margin-bottom: 2rem;
			li {
				list-style-position: inside;
				line-height: 42px;
				border-bottom: 1px solid #f58700;
				a {
					color: #333;
				}
			}
		}
	}
}



/* ↓ COMMON SETTINGS
/* FOR WORD PRESS TEMPLATES
------------------------------ */
.single {
	.post-title {
		width: 595px;
		// margin: 0 auto 1rem;
		margin-bottom: 1rem;
		min-height: 74px;
		background: url(../img/common/or_bracket_left.png) left top no-repeat;
		.bracket-wrapper {
			min-height: 74px;
			background: url(../img/common/or_bracket_right.png) right bottom no-repeat;
		}
		h3 {
			text-align: center;
			font-size: 1.375rem;
			padding-left: 1rem;
			padding-right: 1rem;
			padding-top: 1.5rem;
		}
	}

	.wp_social_bookmarking_light {
		margin-bottom: 2rem;
		ul {
			margin-bottom: 0;
			padding-left: 0;
			list-style: none;
			li {
				margin-left: 1rem;
				margin-bottom: 0;
			}
		}
	}

	.post-body {
		padding-bottom: 4rem;
		border-bottom: 1px solid #666;
		p {
			margin-bottom: 0;
		}
		img {
			// padding-top: 1rem;
			padding-bottom: 1.25rem;
			max-width: 100%;
		}
	}

	.post-meta {
		margin-bottom: 1rem;
		p {
			font-size: .8125rem;
			margin-bottom: .25rem;
			a {
				color: #333;
			}
		&.post-date {
			padding-left: 1.5rem;
			background: url(../img/common/icon_date.png) 0 0 no-repeat;

		}
		&.post-category {
			padding-left: 1.5rem;
			background: url(../img/common/icon_category.png) 0 3px no-repeat;
		}
		&.post-hash {
			padding-left: 1.5rem;
			background: url(../img/common/icon_hash.png) 0 1px no-repeat;
		}
		}
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #666;
	}

}

.pagelinks {
	ul {
		padding-left: 0;
		margin-bottom: 0;
		li {
			list-style: none;
			margin-bottom: 0;
			a {
				color: #333;
			}
		&.prev {
			padding-left: 1.25rem;
			background: url(../img/common/bullet_left_angle.png) 0 4px no-repeat;
		}
		&.next {
			padding-left: 1.25rem;
			background: url(../img/common/bullet_right_angle.png) 0 4px no-repeat;
		}
		}
	}
}

.blog.index,
.blog.category,
.blog.tags {
	.pagelinks {
		padding-left: 2rem;
	}
}

.sidebar {
	.side-title {
		font-size: .8125rem;
		margin-bottom: 1rem;
	}
	min-height: 600px;
}

.sidebar {
	input:hover,
	textarea:hover,
	input:focus,
	textarea:focus {
		border-color: #f58700;
		box-shadow: 0px 0px 6px rgba(245,135,0,1);
	}
	input[type="file"]:hover,
	input[type="file"]:focus {
		border-color: none;
		box-shadow: none;
	}
}


/* ↑ COMMON SETTINGS
/* FOR WORD PRESS TEMPLATES
------------------------------ */

.largeclass-name {
	text-align: center;
	// padding-left: 1.5rem;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	// font-weight: bold;
	color: #f58700;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	background: url(../img/common/divider_or.png) center bottom no-repeat;
}




/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	.blog.index,
	.blog.category,
	.blog.tags {
		.post-lists {
			.container {
				max-width: 1100px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.blog.notfound {
		.post-single-t2 {
			.container {
				max-width: 800px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.blog.search {
		.post-list {
			.container {
				max-width: 1060px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}


@media (min-width: 992px) and (max-width: 1199px) {
	.blog.index,
	.blog.category,
	.blog.tags {
		.post-lists {
			.post-item {
				padding-left: .75rem;
				padding-right: .75rem;
				margin-bottom: 2rem;
			}
			.post-wrapper {
				padding: 1.5rem 1.5rem 1.25rem 1.5rem;
				.post-title {
					font-size: 1.25rem;
					margin-bottom: 1.5rem;
				}
				.post-datas {
					padding-left: 1rem;
				}
			}
		}
	}

	.voice.index,
	.voice.category,
	.voice.tags {
		.post-lists {
			.post-wrapper {
				padding: 2.25rem 3rem 2rem 3rem;
			}
		}
	}

	.topics.index,
	.topics.category,
	.topics.tags {
		.post-lists {
			.post-wrapper {
				padding: 2.25rem 3rem 2rem 3rem;
			}
		}
	}

	.blog {
		.category-list {
			.container {
				max-width: 1200px;
				padding-left: 15px;
				padding-right: 15px;
			}
			ul {
				max-width: 990px;
				margin: 0 auto;
				li {
					min-width: 270px;
					margin-left: 30px;
					margin-right: 30px;
				}
			}
		}
	}

	.faq.index,
	.faq.category,
	.faq.tags {
		.post-lists {
			.post-wrapper {
				.post-title {
					margin-bottom: .5rem;
				}
				.post-datas {
					margin-left: 1.5rem;
				}
			}
		}
	}

}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}



// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {

	.blog.index,
	.blog.category,
	.blog.tags  {
		.post-lists {
			padding-bottom: 4rem;
			.post-item {
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				margin-bottom: 2.5rem;
			}
			.post-wrapper {
				padding: 26px 25px 22px 25px;
				.post-title {
					font-size: 1.25rem;
					margin-bottom: 1rem;
				}
				.post-datas {
					padding-left: .75rem;
				}
			}
		}
	}

	.voice.index,
	.voice.category,
	.voice.tags {
		.post-lists {
			.post-wrapper {
				padding: 2rem 2.5rem 1.75rem 2.5rem;
			}
		}
	}

	.topics.index,
	.topics.category,
	.topics.tags {
		.post-lists {
			.post-wrapper {
				padding: 2rem 2.5rem 1.75rem 2.5rem;
			}
		}
	}

	.faq.index,
	.faq.category,
	.faq.tags {
		.post-lists {
			.post-wrapper {
				h3.post-title {
					margin-bottom: .5rem;
				}
				.post-datas {
					margin-left: 1.75rem;
				}
			}
		}
	}


	.blog.single {
		.post {
			.container {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.post-content {
				padding-right: 0;
				margin-bottom: 4rem;
			}
		.sidebar {
			padding-left: 0;
			ul {
				display: inline-flex;
				flex-wrap: wrap;
				padding-left: 0;
				margin-bottom: 3rem;
				li {
					list-style: none;
					line-height: 1.8;
					border-bottom: 0;
					margin-right: 1.25rem;
					a {
						text-decoration: underline;
						text-decoration-color: #f58700;
					}
				}
			}
		}
	}

	.single {
		.post-title {
			width: 595px;
			margin: 0 auto 1rem;
		}
		.post-body {
			padding-bottom: 4rem;
			border-bottom: 1px solid #666;
			p {
				margin-bottom: 0;
			}
			img {
				display: block;
				margin: 0 auto;
			}
		}
		.post-meta {
			margin-bottom: 1rem;
			p {
				font-size: .8125rem;
				margin-bottom: .25rem;
			}
		}
	}

	.blog {
		.category-list {
			.container {
				max-width: 1200px;
				padding-left: 15px;
				padding-right: 15px;
			}
			h3 {
				font-size: 1.25rem;
				margin-bottom: 1.25rem;
			}
			ul {
				padding-left: 0;
				li {
					text-align: center;
					min-width: auto;
					list-style: none;
					line-height: 1.8;
					border-bottom: 0;
					margin-left: 0;
					margin-right: 1.25rem;
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.blog.search {
		.post {
			.container {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.post-content {
				padding-right: 0;
				padding-bottom: 4rem;
			}
		.sidebar {
			padding-left: 0;
			padding-bottom: 6rem;
			ul {
				display: inline-flex;
				flex-wrap: wrap;
				padding-left: 0;
				margin-bottom: 3rem;
				li {
					list-style: none;
					line-height: 1.8;
					border-bottom: 0;
					margin-right: 1.25rem;
					a {
						text-decoration: underline;
						text-decoration-color: #f58700;
					}
				}
			}
		}
	}

	.sidebar {
		min-height: auto;
		padding-bottom: 6rem;
	}

}

// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	.blog.index,
	.blog.category,
	.blog.tags {
		.post-lists {
			padding-bottom: 2.5rem;
			.post-item {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 2rem;
			}
			.post-wrapper {
				padding: 1.5rem 1.25rem 1.25rem 1.25rem;
				.post-title {
					font-size: 1rem;
					margin-bottom: 1rem;
					line-height: 1.4;
				}
				.post-thumb {
					img {
						display: block;
						margin: 0 auto 1rem;
						// max-width: 100%;
						// width: 100%;
						max-width: 180px;
						max-height: 120px;
						object-fit: cover;
						object-position: 50% 50%;
					}
				}
				.post-datas {
					padding-left: 0;
				}
			}
		}
	}

	.voice.index,
	.voice.category,
	.voice.tags {
		.post-lists {
			.post-wrapper {
				padding: 1.5rem 1.25rem 1.25rem 1.25rem;
			}
		}
	}
	.topics.index,
	.topics.category,
	.topics.tags {
		.post-lists {
			.post-wrapper {
				padding: 1.5rem 1.25rem 1.25rem 1.25rem;
			}
		}
	}


	.faq.index,
	.faq.category,
	.faq.tags {
		.post-lists {
			.post-wrapper {
				h3.post-title {
					margin-bottom: .5rem;
				}
				.post-datas {
					margin-left: 2.5rem;
				}
			}
		}
	}

	.single {
		.post-title {
			width: auto;
			margin: 0 auto 1rem;
			h3 {
				text-align: center;
				font-size: 1.25rem;
			}
		}
		.post-body {
			padding-bottom: 2.5rem;
		}
	}

	.blog.index,
	.blog.category,
	.blog.tags {
		.pagelinks {
			padding-left: 0;
		}
	}

	.blog.notfound {
		.post-single-t2 {
			min-height: auto;
			padding-bottom: 2rem;
			.post-title {
				width: auto;
				margin: 0 auto 2.5rem;
				h3 {
					font-size: 1.25rem;
				}
			}
			.post-body {
				padding-bottom: 2.5rem;
			}
		}
	}

}