@charset "UTF-8";
/* FOR BLOG DIRECTORY
/* BLOG.SCSS -> BLOG.CSS
------------------------------ */
.bottom-links.under-category-links {
  padding-top: 148px;
  background: #E8EDEB url(../img/common/wave_orange.png) top center repeat-x; }

.blog.index .post-lists,
.blog.category .post-lists,
.blog.tags .post-lists {
  padding-bottom: 4rem; }
  .blog.index .post-lists .container,
  .blog.category .post-lists .container,
  .blog.tags .post-lists .container {
    max-width: 1100px;
    padding-left: 0;
    padding-right: 0; }
  .blog.index .post-lists .post-item,
  .blog.category .post-lists .post-item,
  .blog.tags .post-lists .post-item {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 2.5rem; }
  .blog.index .post-lists .post-wrapper,
  .blog.category .post-lists .post-wrapper,
  .blog.tags .post-lists .post-wrapper {
    height: 100%;
    border: 2px solid #f58700;
    border-radius: .75rem;
    background-color: #fff;
    padding: 26px 25px 22px 25px;
    box-shadow: 2px 2px 0 1px #E6EBE9; }
    .blog.index .post-lists .post-wrapper .post-title,
    .blog.category .post-lists .post-wrapper .post-title,
    .blog.tags .post-lists .post-wrapper .post-title {
      font-size: 1.25rem;
      margin-bottom: 1.5rem; }
      .blog.index .post-lists .post-wrapper .post-title a,
      .blog.category .post-lists .post-wrapper .post-title a,
      .blog.tags .post-lists .post-wrapper .post-title a {
        color: #333; }
    .blog.index .post-lists .post-wrapper .post-thumb img,
    .blog.category .post-lists .post-wrapper .post-thumb img,
    .blog.tags .post-lists .post-wrapper .post-thumb img {
      width: 180px;
      height: 120px;
      object-fit: cover;
      object-position: 50% 50%; }
    .blog.index .post-lists .post-wrapper .post-datas,
    .blog.category .post-lists .post-wrapper .post-datas,
    .blog.tags .post-lists .post-wrapper .post-datas {
      padding-left: .5rem; }
      .blog.index .post-lists .post-wrapper .post-datas p,
      .blog.category .post-lists .post-wrapper .post-datas p,
      .blog.tags .post-lists .post-wrapper .post-datas p {
        font-size: .8125rem;
        margin-bottom: 0; }
        .blog.index .post-lists .post-wrapper .post-datas p.post-meta,
        .blog.category .post-lists .post-wrapper .post-datas p.post-meta,
        .blog.tags .post-lists .post-wrapper .post-datas p.post-meta {
          color: #f58700;
          margin-bottom: .25rem; }

.blog.indexwp .post-content {
  min-height: 480px; }

.blog.notfound .post-single-t2 {
  min-height: 480px;
  padding-bottom: 6rem; }
  .blog.notfound .post-single-t2 .container {
    max-width: 800px;
    padding-left: 0;
    padding-right: 0; }
  .blog.notfound .post-single-t2 .post-title {
    width: 595px;
    margin: 0 auto 4rem;
    min-height: 74px;
    background: url(../img/common/or_bracket_left.png) left top no-repeat; }
    .blog.notfound .post-single-t2 .post-title .bracket-wrapper {
      min-height: 74px;
      background: url(../img/common/or_bracket_right.png) right bottom no-repeat; }
    .blog.notfound .post-single-t2 .post-title h3 {
      text-align: center;
      font-size: 1.375rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1.5rem; }
  .blog.notfound .post-single-t2 .post-body {
    padding-bottom: 4rem; }
    .blog.notfound .post-single-t2 .post-body p {
      margin-bottom: 0; }

.blog.notfound.notfound .post-single-t2 .post-title h3 {
  color: #f58700; }

.blog.notfound.notfound .post-single-t2 .post-body p {
  margin-bottom: 1rem; }
  .blog.notfound.notfound .post-single-t2 .post-body p a {
    text-decoration: underline; }

.works.index .post-lists .post-wrapper,
.works.category .post-lists .post-wrapper,
.works.tags .post-lists .post-wrapper {
  padding: 26px 25px 22px 25px; }

.faq.index .post-lists .post-item,
.faq.category .post-lists .post-item,
.faq.tags .post-lists .post-item {
  max-width: 796px;
  margin: 0 auto 2.5rem; }

.faq.index .post-lists .post-wrapper .post-title,
.faq.category .post-lists .post-wrapper .post-title,
.faq.tags .post-lists .post-wrapper .post-title {
  font-size: 1.25rem;
  margin-bottom: .5rem;
  padding-left: 2.5rem;
  line-height: 1.5rem;
  background: url(../img/faq/icon_q.png) 0 0 no-repeat; }

.faq.index .post-lists .post-wrapper .post-datas,
.faq.category .post-lists .post-wrapper .post-datas,
.faq.tags .post-lists .post-wrapper .post-datas {
  margin-left: 2rem; }

.voice.index .post-lists .post-wrapper,
.voice.category .post-lists .post-wrapper,
.voice.tags .post-lists .post-wrapper {
  padding: 2.25rem 3rem 2rem 3rem; }

.voice.index .post-lists .post-item,
.voice.category .post-lists .post-item,
.voice.tags .post-lists .post-item {
  max-width: 900px;
  margin: 0 auto 2.5rem; }

.topics.index .post-lists .post-wrapper,
.topics.category .post-lists .post-wrapper,
.topics.tags .post-lists .post-wrapper {
  padding: 2.25rem 3rem 2rem 3rem; }

.topics.index .post-lists .post-item,
.topics.category .post-lists .post-item,
.topics.tags .post-lists .post-item {
  max-width: 900px;
  margin: 0 auto 2.5rem; }

/* CATEGORY LIST AT PAGE BOTTOM
/* FOR WORD PRESS TEMPLATES
/* (.unit.category-list.toco)
------------------------------ */
.blog .category-list {
  color: #fff; }
  .blog .category-list .bg-wrapper {
    padding-bottom: 2rem; }
  .blog .category-list .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .blog .category-list h3 {
    font-size: 1.3125rem;
    margin-bottom: 3rem;
    font-weight: normal; }
  .blog .category-list ul {
    max-width: 1050px;
    margin: 0 auto;
    padding-left: 0;
    text-align: left;
    display: inline-flex;
    flex-wrap: wrap; }
    .blog .category-list ul li {
      min-width: 270px;
      list-style-position: inside;
      line-height: 42px;
      border-bottom: 2px solid #fff;
      margin-left: 40px;
      margin-right: 40px; }
      .blog .category-list ul li a {
        color: #fff; }

.blog.single .post {
  padding-bottom: 4rem; }
  .blog.single .post .container {
    max-width: 1060px;
    padding-left: 40px;
    padding-right: 40px; }

.blog.single .post-content {
  padding-right: 40px; }

.blog.single .sidebar {
  padding-left: 40px; }
  .blog.single .sidebar ul {
    padding-left: 0;
    margin-bottom: 2rem; }
    .blog.single .sidebar ul li {
      list-style-position: inside;
      line-height: 42px;
      border-bottom: 1px solid #f58700; }
      .blog.single .sidebar ul li a {
        color: #333; }

.blog.search .largeclass-name {
  margin-bottom: 2rem; }

.blog.search .post-list .container {
  max-width: 1060px;
  padding-left: 40px;
  padding-right: 40px; }

.blog.search .post-content {
  padding-bottom: 4rem;
  padding-right: 40px; }
  .blog.search .post-content .post-item {
    margin-bottom: 2.5rem; }
  .blog.search .post-content .post-title {
    font-size: 1.25rem;
    margin-bottom: .25rem; }
    .blog.search .post-content .post-title a {
      color: #333; }
  .blog.search .post-content .post-datas p {
    font-size: .8125rem;
    margin-bottom: 0; }
    .blog.search .post-content .post-datas p.post-date {
      margin-bottom: 0; }
      .blog.search .post-content .post-datas p.post-date a {
        color: #333; }
    .blog.search .post-content .post-datas p.post-meta {
      color: #f58700;
      margin-bottom: .25rem; }
      .blog.search .post-content .post-datas p.post-meta a {
        color: #f58700; }
  .blog.search .post-content .pagelinks {
    padding-left: 0; }

.blog.search .sidebar {
  padding-left: 40px; }
  .blog.search .sidebar ul {
    padding-left: 0;
    margin-bottom: 2rem; }
    .blog.search .sidebar ul li {
      list-style-position: inside;
      line-height: 42px;
      border-bottom: 1px solid #f58700; }
      .blog.search .sidebar ul li a {
        color: #333; }

/* ↓ COMMON SETTINGS
/* FOR WORD PRESS TEMPLATES
------------------------------ */
.single .post-title {
  width: 595px;
  margin-bottom: 1rem;
  min-height: 74px;
  background: url(../img/common/or_bracket_left.png) left top no-repeat; }
  .single .post-title .bracket-wrapper {
    min-height: 74px;
    background: url(../img/common/or_bracket_right.png) right bottom no-repeat; }
  .single .post-title h3 {
    text-align: center;
    font-size: 1.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem; }

.single .wp_social_bookmarking_light {
  margin-bottom: 2rem; }
  .single .wp_social_bookmarking_light ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    .single .wp_social_bookmarking_light ul li {
      margin-left: 1rem;
      margin-bottom: 0; }

.single .post-body {
  padding-bottom: 4rem;
  border-bottom: 1px solid #666; }
  .single .post-body p {
    margin-bottom: 0; }
  .single .post-body img {
    padding-bottom: 1.25rem;
    max-width: 100%; }

.single .post-meta {
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #666; }
  .single .post-meta p {
    font-size: .8125rem;
    margin-bottom: .25rem; }
    .single .post-meta p a {
      color: #333; }
    .single .post-meta p.post-date {
      padding-left: 1.5rem;
      background: url(../img/common/icon_date.png) 0 0 no-repeat; }
    .single .post-meta p.post-category {
      padding-left: 1.5rem;
      background: url(../img/common/icon_category.png) 0 3px no-repeat; }
    .single .post-meta p.post-hash {
      padding-left: 1.5rem;
      background: url(../img/common/icon_hash.png) 0 1px no-repeat; }

.pagelinks ul {
  padding-left: 0;
  margin-bottom: 0; }
  .pagelinks ul li {
    list-style: none;
    margin-bottom: 0; }
    .pagelinks ul li a {
      color: #333; }
    .pagelinks ul li.prev {
      padding-left: 1.25rem;
      background: url(../img/common/bullet_left_angle.png) 0 4px no-repeat; }
    .pagelinks ul li.next {
      padding-left: 1.25rem;
      background: url(../img/common/bullet_right_angle.png) 0 4px no-repeat; }

.blog.index .pagelinks,
.blog.category .pagelinks,
.blog.tags .pagelinks {
  padding-left: 2rem; }

.sidebar {
  min-height: 600px; }
  .sidebar .side-title {
    font-size: .8125rem;
    margin-bottom: 1rem; }

.sidebar input:hover,
.sidebar textarea:hover,
.sidebar input:focus,
.sidebar textarea:focus {
  border-color: #f58700;
  box-shadow: 0px 0px 6px #f58700; }

.sidebar input[type="file"]:hover,
.sidebar input[type="file"]:focus {
  border-color: none;
  box-shadow: none; }

/* ↑ COMMON SETTINGS
/* FOR WORD PRESS TEMPLATES
------------------------------ */
.largeclass-name {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #f58700;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  background: url(../img/common/divider_or.png) center bottom no-repeat; }

/* RESPONSIVE CSS
-------------------------------------------------- */
@media (max-width: 1199px) {
  .blog.index .post-lists .container,
  .blog.category .post-lists .container,
  .blog.tags .post-lists .container {
    max-width: 1100px;
    padding-left: 15px;
    padding-right: 15px; }
  .blog.notfound .post-single-t2 .container {
    max-width: 800px;
    padding-left: 15px;
    padding-right: 15px; }
  .blog.search .post-list .container {
    max-width: 1060px;
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .blog.index .post-lists .post-item,
  .blog.category .post-lists .post-item,
  .blog.tags .post-lists .post-item {
    padding-left: .75rem;
    padding-right: .75rem;
    margin-bottom: 2rem; }
  .blog.index .post-lists .post-wrapper,
  .blog.category .post-lists .post-wrapper,
  .blog.tags .post-lists .post-wrapper {
    padding: 1.5rem 1.5rem 1.25rem 1.5rem; }
    .blog.index .post-lists .post-wrapper .post-title,
    .blog.category .post-lists .post-wrapper .post-title,
    .blog.tags .post-lists .post-wrapper .post-title {
      font-size: 1.25rem;
      margin-bottom: 1.5rem; }
    .blog.index .post-lists .post-wrapper .post-datas,
    .blog.category .post-lists .post-wrapper .post-datas,
    .blog.tags .post-lists .post-wrapper .post-datas {
      padding-left: 1rem; }
  .voice.index .post-lists .post-wrapper,
  .voice.category .post-lists .post-wrapper,
  .voice.tags .post-lists .post-wrapper {
    padding: 2.25rem 3rem 2rem 3rem; }
  .topics.index .post-lists .post-wrapper,
  .topics.category .post-lists .post-wrapper,
  .topics.tags .post-lists .post-wrapper {
    padding: 2.25rem 3rem 2rem 3rem; }
  .blog .category-list .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .blog .category-list ul {
    max-width: 990px;
    margin: 0 auto; }
    .blog .category-list ul li {
      min-width: 270px;
      margin-left: 30px;
      margin-right: 30px; }
  .faq.index .post-lists .post-wrapper .post-title,
  .faq.category .post-lists .post-wrapper .post-title,
  .faq.tags .post-lists .post-wrapper .post-title {
    margin-bottom: .5rem; }
  .faq.index .post-lists .post-wrapper .post-datas,
  .faq.category .post-lists .post-wrapper .post-datas,
  .faq.tags .post-lists .post-wrapper .post-datas {
    margin-left: 1.5rem; } }

@media (max-width: 991px) {
  .blog.index .post-lists,
  .blog.category .post-lists,
  .blog.tags .post-lists {
    padding-bottom: 4rem; }
    .blog.index .post-lists .post-item,
    .blog.category .post-lists .post-item,
    .blog.tags .post-lists .post-item {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 2.5rem; }
    .blog.index .post-lists .post-wrapper,
    .blog.category .post-lists .post-wrapper,
    .blog.tags .post-lists .post-wrapper {
      padding: 26px 25px 22px 25px; }
      .blog.index .post-lists .post-wrapper .post-title,
      .blog.category .post-lists .post-wrapper .post-title,
      .blog.tags .post-lists .post-wrapper .post-title {
        font-size: 1.25rem;
        margin-bottom: 1rem; }
      .blog.index .post-lists .post-wrapper .post-datas,
      .blog.category .post-lists .post-wrapper .post-datas,
      .blog.tags .post-lists .post-wrapper .post-datas {
        padding-left: .75rem; }
  .voice.index .post-lists .post-wrapper,
  .voice.category .post-lists .post-wrapper,
  .voice.tags .post-lists .post-wrapper {
    padding: 2rem 2.5rem 1.75rem 2.5rem; }
  .topics.index .post-lists .post-wrapper,
  .topics.category .post-lists .post-wrapper,
  .topics.tags .post-lists .post-wrapper {
    padding: 2rem 2.5rem 1.75rem 2.5rem; }
  .faq.index .post-lists .post-wrapper h3.post-title,
  .faq.category .post-lists .post-wrapper h3.post-title,
  .faq.tags .post-lists .post-wrapper h3.post-title {
    margin-bottom: .5rem; }
  .faq.index .post-lists .post-wrapper .post-datas,
  .faq.category .post-lists .post-wrapper .post-datas,
  .faq.tags .post-lists .post-wrapper .post-datas {
    margin-left: 1.75rem; }
  .blog.single .post .container {
    padding-left: 15px;
    padding-right: 15px; }
  .blog.single .post-content {
    padding-right: 0;
    margin-bottom: 4rem; }
  .blog.single .sidebar {
    padding-left: 0; }
    .blog.single .sidebar ul {
      display: inline-flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 3rem; }
      .blog.single .sidebar ul li {
        list-style: none;
        line-height: 1.8;
        border-bottom: 0;
        margin-right: 1.25rem; }
        .blog.single .sidebar ul li a {
          text-decoration: underline;
          text-decoration-color: #f58700; }
  .single .post-title {
    width: 595px;
    margin: 0 auto 1rem; }
  .single .post-body {
    padding-bottom: 4rem;
    border-bottom: 1px solid #666; }
    .single .post-body p {
      margin-bottom: 0; }
    .single .post-body img {
      display: block;
      margin: 0 auto; }
  .single .post-meta {
    margin-bottom: 1rem; }
    .single .post-meta p {
      font-size: .8125rem;
      margin-bottom: .25rem; }
  .blog .category-list .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .blog .category-list h3 {
    font-size: 1.25rem;
    margin-bottom: 1.25rem; }
  .blog .category-list ul {
    padding-left: 0; }
    .blog .category-list ul li {
      text-align: center;
      min-width: auto;
      list-style: none;
      line-height: 1.8;
      border-bottom: 0;
      margin-left: 0;
      margin-right: 1.25rem; }
      .blog .category-list ul li a {
        text-decoration: underline; }
  .blog.search .post .container {
    padding-left: 15px;
    padding-right: 15px; }
  .blog.search .post-content {
    padding-right: 0;
    padding-bottom: 4rem; }
  .blog.search .sidebar {
    padding-left: 0;
    padding-bottom: 6rem; }
    .blog.search .sidebar ul {
      display: inline-flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 3rem; }
      .blog.search .sidebar ul li {
        list-style: none;
        line-height: 1.8;
        border-bottom: 0;
        margin-right: 1.25rem; }
        .blog.search .sidebar ul li a {
          text-decoration: underline;
          text-decoration-color: #f58700; }
  .sidebar {
    min-height: auto;
    padding-bottom: 6rem; } }

@media (max-width: 767px) {
  .blog.index .post-lists,
  .blog.category .post-lists,
  .blog.tags .post-lists {
    padding-bottom: 2.5rem; }
    .blog.index .post-lists .post-item,
    .blog.category .post-lists .post-item,
    .blog.tags .post-lists .post-item {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 2rem; }
    .blog.index .post-lists .post-wrapper,
    .blog.category .post-lists .post-wrapper,
    .blog.tags .post-lists .post-wrapper {
      padding: 1.5rem 1.25rem 1.25rem 1.25rem; }
      .blog.index .post-lists .post-wrapper .post-title,
      .blog.category .post-lists .post-wrapper .post-title,
      .blog.tags .post-lists .post-wrapper .post-title {
        font-size: 1rem;
        margin-bottom: 1rem;
        line-height: 1.4; }
      .blog.index .post-lists .post-wrapper .post-thumb img,
      .blog.category .post-lists .post-wrapper .post-thumb img,
      .blog.tags .post-lists .post-wrapper .post-thumb img {
        display: block;
        margin: 0 auto 1rem;
        max-width: 180px;
        max-height: 120px;
        object-fit: cover;
        object-position: 50% 50%; }
      .blog.index .post-lists .post-wrapper .post-datas,
      .blog.category .post-lists .post-wrapper .post-datas,
      .blog.tags .post-lists .post-wrapper .post-datas {
        padding-left: 0; }
  .voice.index .post-lists .post-wrapper,
  .voice.category .post-lists .post-wrapper,
  .voice.tags .post-lists .post-wrapper {
    padding: 1.5rem 1.25rem 1.25rem 1.25rem; }
  .topics.index .post-lists .post-wrapper,
  .topics.category .post-lists .post-wrapper,
  .topics.tags .post-lists .post-wrapper {
    padding: 1.5rem 1.25rem 1.25rem 1.25rem; }
  .faq.index .post-lists .post-wrapper h3.post-title,
  .faq.category .post-lists .post-wrapper h3.post-title,
  .faq.tags .post-lists .post-wrapper h3.post-title {
    margin-bottom: .5rem; }
  .faq.index .post-lists .post-wrapper .post-datas,
  .faq.category .post-lists .post-wrapper .post-datas,
  .faq.tags .post-lists .post-wrapper .post-datas {
    margin-left: 2.5rem; }
  .single .post-title {
    width: auto;
    margin: 0 auto 1rem; }
    .single .post-title h3 {
      text-align: center;
      font-size: 1.25rem; }
  .single .post-body {
    padding-bottom: 2.5rem; }
  .blog.index .pagelinks,
  .blog.category .pagelinks,
  .blog.tags .pagelinks {
    padding-left: 0; }
  .blog.notfound .post-single-t2 {
    min-height: auto;
    padding-bottom: 2rem; }
    .blog.notfound .post-single-t2 .post-title {
      width: auto;
      margin: 0 auto 2.5rem; }
      .blog.notfound .post-single-t2 .post-title h3 {
        font-size: 1.25rem; }
    .blog.notfound .post-single-t2 .post-body {
      padding-bottom: 2.5rem; } }
